<router-outlet></router-outlet>

<ng-template #accessModal>
  <div class="modal-body">
    User is not assigned to the client application. Please Contact
    Administrator.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="logout()">OK</button>
  </div>
</ng-template>

<ng-template #loadModal>
  <div class="modal-body test">
    <div class="loader-block"><!--  continuous loading-->
      <img src="./assets/loading-spinner-text.gif" />
    </div>
  </div>
</ng-template>
