import { Injectable } from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {APPLICATION_CONFIGURATION} from '../config/config';

@Injectable()
export class HttpService {
  etag: any;

  constructor(private http: HttpClient) { }

  buildUrl(resourceUrl: any) {
    return APPLICATION_CONFIGURATION.CONFIG.baseUrl + resourceUrl;
  }

  get(url: string) {
    return this.http.get(this.buildUrl(url));
  }

  post(url: string, data: any) {
    return this.http.post(this.buildUrl(url), data);
  }

  delete(url: string) {
    return this.http.delete(this.buildUrl(url));
  }

  put(url: string, data: any) {
    return this.http.put(this.buildUrl(url), data);
  }

  getWithCredentials(url) {
    return this.http.get(url, { withCredentials: true });
  }

  deleteWithCredentials(url) {
    return this.http.delete(url, { withCredentials: true });
  }
}
