import {
    Injectable
} from '@angular/core';
import {
    DataService
} from './data.service';
import { HttpService } from './http.service';
import * as moment from 'moment';
import {APPLICATION_CONFIGURATION} from '../config/config';

@Injectable()
export class SessionService {
    lastInteractedTime = moment.utc();
    lastUpdatedTime: any;
    sessionExpireTime = moment.utc();
    interval: any;

    constructor(private dataService: DataService, private http: HttpService) {
    }

    getCurrentSession() {
        this.http.getWithCredentials(APPLICATION_CONFIGURATION.CONFIG.oktaCurrentSession).subscribe(
            (res: any) => {
                if (res && res.expiresAt) {
                    if (this.interval) {
                        clearInterval(this.interval);
                    }
                    this.lastUpdatedTime = moment.utc();
                    this.lastInteractedTime = moment.utc();
                    this.sessionExpireTime = moment.utc(res.expiresAt);
                    this.interval = setInterval(() => {
                        if (this.lastInteractedTime.isAfter(this.lastUpdatedTime)) {
                            const differ = this.lastInteractedTime.diff(this.lastUpdatedTime , 'seconds');
                            this.lastUpdatedTime = this.lastInteractedTime;
                            this.sessionExpireTime.add(differ, 'seconds');
                        } else {
                            const currentTime = moment.utc();
                            const diff = this.sessionExpireTime.diff(currentTime, 'seconds') - 60;
                            if (diff <= 0) {
                                this.dataService.updateTimeoutWarning();
                                clearInterval(this.interval);
                            }
                        }
                    }, 1000);
                }
            },
            (err) => {
                if (err.status === 404) {
                    this.dataService.updateLogoutTrigger()
                }
                console.log(err);
            }
        );
    }
}
