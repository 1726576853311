import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-post-logout',
  templateUrl: '../views/post-logout.component.html',
  styleUrls: ['../styles/post-logout.component.less']
})
export class PostLogoutComponent {
  constructor(private router: Router) {
    window.location.href = `${window.location.origin}/logged-out.html`;
  }

  clickSignIn() {
    this.router.navigate(['/uee']);
  }
}
