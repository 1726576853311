import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { switchMap } from 'rxjs/operators';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { HttpService } from '../services/http.service';
import { DataService } from '../services/data.service';
import { httpStatusCodes, APPLICATION_CONFIGURATION } from '../config/config';
import { POST_MSG } from '../../modules/uee/uee.config';
import { SessionService } from '../services/session-check.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
   // private oktaAuth: OktaAuthService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private httpService: HttpService,
    private dataService: DataService,
    private sessionService: SessionService
  ) { }

  addAuthHeader(request) {
    const tokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    const authHeader = tokenStorage && tokenStorage.accessToken && tokenStorage.accessToken.accessToken;
    if (authHeader) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${authHeader}`,
          'Content-Type': 'application/json'
        }
      });
    } else {
      this.dataService.updateLogoutTrigger();
      window.parent.postMessage(
        {
          type: POST_MSG.LOGOUT
        },
        '*'
      );
    }
    return request;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.url !== APPLICATION_CONFIGURATION.CONFIG.oktaCurrentSession) {
      request = this.addAuthHeader(request);
    }

    if (request.method === 'PUT') {
      request.headers.append('If-Match', this.httpService.etag);
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error.status === httpStatusCodes.unauthorized) {
          return from(of(this._oktaAuth.getAccessToken()).toPromise()).pipe(
            switchMap(() => {
              this.sessionService.getCurrentSession();
              request = this.addAuthHeader(request);
              return next.handle(request);
            }),
            catchError(err => {
              if (err.status === httpStatusCodes.unauthorized) {
                this.dataService.updateLogoutTrigger();
                window.parent.postMessage(
                  {
                    type: POST_MSG.LOGOUT
                  },
                  '*'
                );
              }
              return throwError(err);
            })
          );
        }
        if (error.status === httpStatusCodes.forbidden) {
          this.dataService.updateLogoutTrigger();
          window.parent.postMessage(
            {
              type: POST_MSG.LOGOUT
            },
            '*'
          );
        }
        return throwError(error);
      })
    );
  }
}
