import { Component, OnInit, Inject, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { DataService } from './shared/services/data.service';
import { SessionService } from './shared/services/session-check.service';
import { POST_MSG } from './modules/uee/uee.config';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean;
  isLoading: boolean;
  getAppAccessSub: any;
  getUserInvalidSub: any;
  getAuthSdkInvalidSub: any;
  @ViewChild('accessModal', { static: true }) accessModal: TemplateRef<any>;
  @ViewChild('loadModal', { static: true }) loadModal: TemplateRef<any>;
  accessModalRef: BsModalRef;
  loadModalRef: BsModalRef;

  constructor(
    public dataService: DataService,
    public oktaAuth: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private router: Router,
    private sessionService: SessionService,
    private modalService: BsModalService
  ) {
    this.sessionService.getCurrentSession();
  }

  async ngOnInit() {
    this.router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          if (
            this.router.url.indexOf('implicit') !== -1 ||
            this.router.url === '/'
          ) {
            setTimeout(() => {
              const div = document.getElementsByTagName('div');
              if (div && div[0] && div[0].innerText.startsWith('AuthSdkError')) {
                console.error(div[0].innerText);
                window.location.href = window.location.origin;
              }
            }, 1000);
            this.loadModalRef = this.modalService.show(this.loadModal, {
              class: 'modal-dialog modal-lg initial-loader bg-none',
              backdrop: "static",
              id: "initial-whitebkgnd",
              keyboard: false,
            });
            setTimeout(() => {
              this.loadModalRef?.hide();
            }, 1000);
          }
          break;
        }
        case event instanceof NavigationEnd: {
          if (
            this.router.url.indexOf('implicit') === -1 &&
            this.router.url !== '/'
          ) {
            this.loadModalRef?.hide();
          }
          break;
        }
        case event instanceof NavigationError: {
          this.checkChunkError(event as NavigationError);
          break;
        }
        default: {
          break;
        }
      }
    });

    this.getAppAccessSub = this.dataService
      .getAppAccess()
      .subscribe((data: boolean) => {
        if (data) {
          this.accessModalRef = this.modalService.show(this.accessModal, {
            class: 'modal-dialog modal-lg',
            backdrop: "static",
            keyboard: false,
          });
        }
      });

    this.getAuthSdkInvalidSub = this.dataService
      .getAuthSdkErrorWarning()
      .subscribe(() => {
        window.location.href = `${window.location.origin}`;
      });
  }

  ngOnDestroy() {
    this.getAppAccessSub.unsubscribe();
  }

  checkChunkError(event: NavigationError) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(event.error.message)) {
      window.location.href = `${window.location.origin}${event.url}`;
    }
  }

  logout() {
    this.accessModalRef?.hide();
    window.parent.postMessage(
      {
        type: POST_MSG.LOGOUT
      },
      '*'
    );
    this.onLogoutClear();
  }

  onLogoutClear() {
    this._oktaAuth.signOut();
    localStorage.setItem('oktaSessionInvalid', '1');
    this._oktaAuth.getAccessToken();
    localStorage.removeItem('okta-token-storage');
    sessionStorage.removeItem('okta-token-storage');
    localStorage.clear();
    // this.oktaAuth.getAccessToken();
    this.changeLocation();
}

changeLocation() {
  window.location.href = `${window.location.origin}/logged-out.html`;
}

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer() {
    this.sessionService.lastInteractedTime = moment.utc();
  }
}
