import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { HttpServiceModule } from './shared/modules/http.module';
import { AppRoutingModule } from './app-routing.module';
import { ErrorHandlingModule } from './error-handler.module';
import { AppComponent } from './app.component';
import { DataService } from './shared/services/data.service';
import { SessionService } from './shared/services/session-check.service';

import { PreInitService, preInitServiceFactory } from './pre-init.service';
// import { OktaConfigProvider } from './okta-config.provider';
import { CryptoService } from './shared/services/crypto.service';
import { PostLogoutComponent } from './shared/components/post-logout.component';
import { APPLICATION_CONFIGURATION } from './shared/config/config';
import { ModalModule } from 'ngx-bootstrap/modal';

// Connect to qa2
  // const oktaAuth = new OktaAuth({
  //   clientId: "0oax6pzwscBe1kd0P0h7",
  //   issuer: "https://navvis.oktapreview.com/oauth2/default",
  // // redirectUri: "http://localhost:4201/implicit/callback",
  //   redirectUri: "https://ueeqa2.coreoflowsandbox.com/implicit/callback"
  // });

// Connect to Local
  // const oktaAuth = new OktaAuth({
  //   clientId: "0oawky29kgpzps4UM0h7",
  //   issuer: "https://navvis.oktapreview.com/oauth2/default",
  //   redirectUri: "http://localhost:4201/implicit/callback",
  //   // redirectUri: "https://devuee1.coreoflowsandbox.com/implicit/callback"
  // });

// Build to UEE - FMC
  const oktaAuth = new OktaAuth({
    "clientId": "0oa47q5ajlTWJvbUf297", // "K04jpLGDwEksLLP/ohuK7tDpXrhKLX3PXEgk3ioNBVg=",
    "issuer": "https://login.coreohealth.com/oauth2/default",
    "redirectUri": "https://fmc-uee.coreohealth.com/implicit/callback"
  });

// Build to UEE - SSM
  // const oktaAuth = new OktaAuth({
  //   "clientId": "0oa2hia0udh3KhRKQ297", // "Aous3o32KwawWXQi+YXIAvBbSj5wJV9M4QeTsmTvj1M=",
  //   "issuer": "https://login.coreohealth.com/oauth2/default",
  //   "redirectUri": "https://ssm-uee.coreohealth.com/implicit/callback"
  // });

// Build to UEE - Allina
  // const oktaAuth = new OktaAuth({
  //   "clientId": "0oal2zz47yI8gkYHf297", // "qWeslFJu1nM/J2PbZJtXcNDpXrhKLX3PXEgk3ioNBVg=",
  //   "issuer": "https://login.coreohealth.com/oauth2/default",
  //   "redirectUri": "https://allina-uee.coreohealth.com/implicit/callback"
  // });

@NgModule({
  declarations: [AppComponent, PostLogoutComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ErrorHandlingModule,
    BrowserAnimationsModule,
    FormsModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    HttpServiceModule,
    ModalModule.forRoot()
  ],
  providers: [
    DataService,
    SessionService,
    PreInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: preInitServiceFactory,
      deps: [PreInitService, Injector],
      multi: true
    },
   // OktaConfigProvider,
    CryptoService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
