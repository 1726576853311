import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DataService {
  private logoutTrigger$ = new Subject();
  private sessionTimeoutWarning$ = new Subject();
  private isAppAccess$ = new Subject();
  private authSdkErrorTrigger$ = new Subject();

  getAuthSdkErrorWarning() {
    return this.authSdkErrorTrigger$;
  }

  updateAuthSdkErrorWarning() {
    this.authSdkErrorTrigger$.next(null);
  }

  sessionTimeoutWarning() {
    return this.sessionTimeoutWarning$;
  }

  updateTimeoutWarning() {
    this.sessionTimeoutWarning$.next(null);
  }

  getAppAccess() {
    return this.isAppAccess$;
  }

  updateAppAccess(data: any) {
    this.isAppAccess$.next(data);
  }

  logoutTrigger() {
    return this.logoutTrigger$;
  }

  updateLogoutTrigger() {
    this.logoutTrigger$.next(null);
  }
}
