import {
  App
} from './models/app';

export const frameStyles = {
  threeView: 'col-4',
  twoView: 'col-6',
  oneView: 'col-12',
  hideView: 'no-display'
};

export const frameSwappingStyles = {
  left: 'float-start',
  right: 'float-end',
  center: 'float-none'
};

export const emptyPanels: App[] = [{
  appName: '',
  isShow: false,
  style: frameStyles.hideView,
  appUrl: '',
  position: 'L',
  url: '',
  isPinned: false,
  isGrantAccess: true,
  applicationId: -1,
  applicationIcon: '',
  iconColor1: '',
  iconColor2: '',
  iconColor3: '',
  iconColor4: '',
  isStretchable: false,
  oktaAppId: 'L',
  appSubName: '',
  loadingShow: false,
  loadingTime: 0,
  actualUrl: '',
  viewSyncUrl: ''
},
{
  appName: '',
  isShow: false,
  style: frameStyles.hideView,
  appUrl: '',
  position: 'C',
  url: '',
  isPinned: false,
  isGrantAccess: true,
  applicationId: -2,
  applicationIcon: '',
  iconColor1: '',
  iconColor2: '',
  iconColor3: '',
  iconColor4: '',
  isStretchable: false,
  oktaAppId: 'C',
  appSubName: '',
  loadingShow: false,
  loadingTime: 0,
  actualUrl: '',
  viewSyncUrl: ''
},
{
  appName: '',
  isShow: false,
  style: frameStyles.hideView,
  appUrl: '',
  position: 'R',
  url: '',
  isPinned: false,
  isGrantAccess: true,
  applicationId: -3,
  applicationIcon: '',
  iconColor1: '',
  iconColor2: '',
  iconColor3: '',
  iconColor4: '',
  isStretchable: false,
  oktaAppId: 'R',
  appSubName: '',
  loadingShow: false,
  loadingTime: 0,
  actualUrl: '',
  viewSyncUrl: ''
}
];

export const MIN_FRAME_WIDTH = {
  ONE_PANEL: 1280,
  TWO_PANEL: 2594,
  THREE_PANEL: 3890
};

export const RecordsPerPage = 6;

export const TOOLBAR_PAGE = {
  onePanel: {
    res720: 20,
    res1080: 33
  },
  twoPanel: 20,
  threePanel: 20
};

export const RESOLUTION_WIDTH = {
  RESOLUTION_1080: 1920,
  popupWidth: 745,
  warningPopupWidth: 600
};

export const APP_CONFIG = {
  CV: 'cv',
  BBV: 'bbv',
  PPV: 'ppv',
  GMV: 'gmv',
  CH: 'ch',
  SZ: 'sz',
  CA: 'ca',
  CC: 'cc'
};

export const POST_MSG = {
  PATIENT_SYNC: 'patient_sync',
  CV_VIEW_CHANGE: 'cv_view_change',
  UEE_CV_VIEW_CHANGE: 'uee_cv_view_change',
  LOGOUT: 'logout',
  CHECK_IN_UEE: 'check_in_uee',
  CHECK_IN_CV: 'check_in_cv',
  EXTEND_SESSION: 'extend_session',
  EXTEND_CA_SESSION: 'extend_ca_session',
  CH_VIEW_SYNC: 'ch_view_sync',
  CH_EXIST_IN_UEE: 'ch_exist_in_uee',
  CV_VIEW_SYNC: 'cv_view_sync',
  FROM_UEE: 'from_uee',
  USER_ACTIVE: 'user_active',
  USER_INACTIVE: 'user_inactive'
};

export const cvConfigValues = {
  key: ['UEEHelpDocLink', 'RefreshCookieCA', 'SyncPopupTimeout', 'UEELoadingTime',
    'UniversalLCR', 'MaxZoomLevel', 'MinZoomLevel']
}

export const panelTypes = {
  ONE_PANEL: 1,
  TWO_PANEL: 2,
  THREE_PANEL: 3
};

export const VIEW_SYNC_APPS = {
  CV: 'CV',
  CC: 'CC',
  CA: 'CA',
  CH: 'CH',
  UEE: 'UEE',
  SZ: 'SYNZI_VC'
}

export const SYNZI_SAFARI = `
<html>
<head>
  <title></title>
  <style>
    .content-div {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      width: 500px;
      height: 86px;
      text-align: center;
      padding: 80px 25px;
      margin-left: -277px;
      margin-top: -125px;
      background: white;
      border: 2px solid white;
      border-radius: 4px;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 16px;
      line-height: 1.6;
      color: #333;
    }
  </style>
</head>
<body>
  <div class="content-div">
    Coreo Connect does not support a Safari browser. For an optimal experience of all applications, use a Google Chrome
    browser.</div>
</body>
</html>
`
