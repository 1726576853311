<div class="row text-center post-logout">
    <img src="app/shared/images/post-logout-bg.png" alt="Sync" class="bg-img" />
    <div class="signin-box">
        <img src="app/shared/images/coreo-logo-post-logout.png" alt="Sync" class="coreo-logo" />
        <div class="content">
            <p>You have been logged out. 
               <br />Click button below to log in.</p>
            <button class="btn btn-primary mt-10" (click)="clickSignIn()">Sign In</button>
        </div>
    </div>
</div>