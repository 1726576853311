import { Injectable, ErrorHandler } from '@angular/core';
import { NgModule } from '@angular/core';
import { DataService } from './shared/services/data.service';

@Injectable()
export class MyErrorHandler implements ErrorHandler {
  constructor(private dataService: DataService) { }

  handleError(error: any) {
    if (
      error &&
      error.rejection &&
      error.rejection.errorCode === 'access_denied'
    ) {
      this.dataService.updateAppAccess(true);
    }
    if (
      error &&
      error.rejection &&
      error.rejection.name === 'AuthSdkError'
    ) {
      this.dataService.updateAuthSdkErrorWarning();
    }
    console.error(error);
    return error;
  }
}

@NgModule({
  imports: [],
  exports: [],
  providers: [{ provide: ErrorHandler, useClass: MyErrorHandler }]
})
export class ErrorHandlingModule { }
