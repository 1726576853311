export const httpStatusCodes = {
  etag: 412,
  notAcceptable: 406,
  forbidden: 403,
  unauthorized: 401
};

export const resolution = {
  normalResolution: 1280
};

export const exceptions = {
  uniqueKeyException: 'SqlUniqueKeyException'
};

export const sessionAliveTime = {
  aliveTimeCA : 14,
  aliveTimeCV : 1
}

export const APPLICATION_CONFIGURATION = {
  CONFIG: {
    baseUrl: '',
    clientUrl: '',
    oktaCurrentSession: ''
  },
  OKTA_CONFIG: {
    clientId: '',
    issuer: '',
    redirectUri: ''
  }
};
